
const year = new Date().getFullYear();
export default function Footer() {
    return (
        <footer>
            <p>© {year} Ryan Varela. Todos direitos reservados.</p>

        </footer>
    );
}

